<template>
  <v-container fluid class="notfound-page pa-0">
    <v-row justify="center" align="center" class="main" no-gutters>
      <v-col cols="12" class="text-center">
        <img class="logo" src="/Image/logo_intro.png" alt="">
      </v-col>
      <v-col cols="12" class="text-center white--text">
        {{$i18n.locale == 'th' ? 'ไม่พบหน้าที่คุณต้องการ': 'Page Not Found'}}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn @click="$router.push('/')" dark>
            {{$i18n.locale == 'th' ? 'กลับหน้าแรก': 'Go to home'}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "notfound",
    mounted() {
      
    }
  }
</script>

<style lang="scss">
  .notfound-page {
    height: 100vh;
    background-image: url('/Image/BG.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    .main {
      height: 100vh;
    }

    .logo {
      width: 40%;
      height: auto;
      max-width: 250px;
    }
  }
</style>